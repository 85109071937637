
.form-group {
	margin-bottom: 18px;

	&:last-child {
		margin-bottom: 0;
	}

	&_lg {
		margin-bottom: 30px;
	}
}

.form-control {
	@include box-sizing(border-box);
	width: 100%;
	background-color: white;
	border: 1px solid #bfbfbf;
	height: 40px;
	@include font-size(16px);
	padding: 10px 16px;
	@include transition(all .2s);
	@include placeholder {
	    color: #7f7f7f;
	    @include opacity(1);
	    font-style: italic;
	}
	
	@at-root {
		textarea#{&} {
			height: 158px;
			resize: vertical;
		}
	}

	&:focus {
		border-color: #000;
		outline: none;
	}

	&_md {
		margin-bottom: 28px;
	}
}

.form-label {
	margin-bottom: 7px;
	@include font-size(16px);
	display: block;
	color: #7f7f7f;
	font-style: italic;
}

.error-field-msg {
	color: #fa0c0c;
	font-weight: 300;
	@include font-size(13px);
	line-height: 15px;
	margin-top: 6px;
}