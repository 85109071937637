.main-content {
	@include box-sizing(border-box);
}

.c-main-slider {
	width: 100%;
	overflow: hidden;
	position: relative;

	.bx-wrapper {
		margin: 0;

		.bx-viewport {
			border: none;
			left: auto;
			background: none;
			box-shadow: none;
		}

		.bx-controls-direction {

			a {
				width: 20px;
				height: 20px;
				top: auto;
				margin-top: 0;
				bottom: 50px;
				font-size: 0;
				position: absolute;

				@media screen and (min-width: $screen-sm) {
					bottom: 130px;
				}	

				@media screen and (min-width: $screen-md) {
					bottom: 150px;
				}		

				@media screen and (min-width: $screen-lg) {
					bottom: 150px;
				}	

				@media screen and (min-width: $screen-lg-extra) {
					bottom: 280px;
				}		

				&:before {
					content: "";
					position: absolute;
					width: 20px;
					height: 90px;
					top: 50%;
					left: 50%;
					margin-top: -45px;
					background: url("../img/base/arr-main-slider.png") no-repeat;
				}

				&.bx-prev {
					left: 20px;
				}

				&.bx-next {
					right: 20px;

					&:before {
						@include rotate(180deg);
					}
				}
			}
		}
	}

	img {
		display: block;
		max-width: 100%;
	}

	.bx-controls {
		margin-left: auto;
		margin-right: auto;
		padding-left: 10px;
		padding-right: 10px;
		max-width: 1200px;
		@include box-sizing(border-box);
		position: relative;
	}
}

.our-products {
	background: url("../img/base/bg-our-products.jpg") no-repeat 50% 0;
	background-size: cover;
	padding-top: 30px;
	padding-bottom: 30px;

	@media screen and (min-width: $screen-md) {
		padding-top: 47px;
		padding-bottom: 47px;
	}	
}

.map {
	height: 270px;
	overflow: hidden;

	@media screen and (min-width: $screen-md) {
		height: 423px;
	}	

	iframe {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.products-cols {
	font-size: 0;
	text-align: center;

	.col {
		width: 50%;
		@include box-sizing(content-box);
		padding: 0 12px 20px;
		display: inline-block;
		vertical-align: top;

		@media screen and (min-width: $screen-lg) {
			width: 224px;
		}	

		&:first-child {

			@media screen and (min-width: $screen-lg) {
				padding-left: 0;
			}
		}

		&:last-child {

			@media screen and (min-width: $screen-lg) {
				padding-right: 0;
			}
		}
	}

	a {
		text-decoration: none;
		text-transform: uppercase;
		font-size: 14px;
		line-height: 20px;
		color: #112434;
		display: block;
		padding: 0 10%;

		@media screen and (min-width: $screen-md) {
			font-size: 26px;
			line-height: 33px;
		}	

		&:hover {
			text-decoration: underline;
		}
	}
}

.product-img-round {
	margin: 0 auto 12px;
	width: 110px;
	height: 110px;
	@include border-radius(50%);
	overflow: hidden;

	@media screen and (min-width: $screen-md) {
		margin: 0 auto 22px;
		width: 212px;
		height: 212px;
	}	

	img {
		display: block;
		max-width: 100%;
	}
}

.c-calculate {
	background: url("../img/base/bg-calculate.jpg") no-repeat 50% 0;
	background-size: cover;
	padding-top: 22px;
	padding-bottom: 22px;

	&_sp {
		background-image: url("../img/base/bg-calculate-sp.jpg");
	}

	> .container {
		position: relative;
	}

	.girl-calculate {
		position: absolute;
		bottom: -22px;
		left: 10px;
		display: none;

		@media screen and (min-width: $screen-md) {
			display: block;
		}	
	}
}

.c-title-section {
	margin-bottom: 30px;
	text-align: center;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 53px;
	}		

	&_sm {
		margin-bottom: 27px;
	}

	.b-title {
		display: inline-block;
		vertical-align: top;
		position: relative;
		text-transform: uppercase;
		color: white;
		padding: 10px 26px;
		font-size: 20px;
		line-height: 24px;
		font-weight: 700;
		z-index: 3;

		@media screen and (min-width: $screen-md) {
			padding: 10px 72px;
			font-size: 40px;
			line-height: 44px;
		}	

		&_blue-colors {

			&:before {
				background-color: #3a93cc;
			}

			&:after {
				background-color: #3abfcc;
			}
		}

		&_dblue-colors {

			&:before {
				background-color: #3d6bb0;
			}

			&:after {
				background-color: #7098d4;
			}
		}

		&_green-colors {

			&:before {
				background-color: #47a95a;
			}

			&:after {
				background-color: #67d37c;
			}
		}

		&_dgreen-colors {

			&:before {
				background-color: #1e959e;
			}

			&:after {
				background-color: #46c2cb;
			}
		}

		&:before {
			content: "";
			position: absolute;
			left: 0; right: 0;
			top: 0; bottom: 0;
			z-index: -1;

			@media screen and (min-width: $screen-md) {
				-webkit-transform: skewX(-30deg);
				transform: skewX(-30deg);
			}
		}

		&:after {
			content: "";
			position: absolute;
			left: -20px; right: 20px;
			top: 5px; bottom: -5px;
			z-index: -1;
			z-index: -2;
			display: none;

			@media screen and (min-width: $screen-md) {
				left: -50px; right: 50px;
				top: 10px; bottom: -10px;
				-webkit-transform: skewX(-30deg);
				transform: skewX(-30deg);
				display: block;
			}
		}
	}
}

.form-calculate {
	background-color: rgba(255, 255, 255, 0.7);
	@include box-sizing(border-box);
	max-width: 460px;
	padding: 20px 36px;
	margin: 0 auto;
	position: relative;
	z-index: 2;

	@media screen and (min-width: $screen-md) {
		padding: 26px 42px 40px;
		float: right;
		margin: 0;
	}	

	&__title {
		margin: 0 -20px 20px;
		text-align: center;
		color: #3a93cc;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 25px;
		line-height: 28px;

		span {
			color: #eea431;
		}
	}
}

.c-glazing {
	background: url("../img/base/bg-glazing.jpg") no-repeat 50% 0;
	background-size: cover;
	padding-top: 26px;
	padding-bottom: 26px;

	@media screen and (min-width: $screen-md) {
		padding-top: 54px;
		padding-bottom: 74px;
	}	
}

.row-glazing {
	@include clearfix;
	position: relative;

	@media screen and (min-width: $screen-lg) {
		background: url("../img/base/img-window-glazing.png") no-repeat 50% 100%;
	}	
}

.col-glazing {
	text-align: center;

	@media screen and (min-width: $screen-md) {
		width: 45%;
	}	

	@media screen and (min-width: $screen-lg) {
		width: 278px;
	}	

	&:first-child {

		@media screen and (min-width: $screen-md) {
			float: left;
		}	

		.b-glazing {

			&__body {
				left: 152px;
				transform-origin: left top 0;

				&:before,
				&:after {
					right: 100%;
					top: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}

				&:after {
					border-color: rgba(255, 255, 255, 0);
					border-right-color: #ffffff;
					border-width: 8px;
					margin-top: -8px;
				}

				&:before {
					border-color: rgba(71, 169, 90, 0);
					border-right-color: #47a95a;
					border-width: 11px;
					margin-top: -11px;
				}
			}
		}
	}

	&:last-child {

		@media screen and (min-width: $screen-md) {
			float: right;
		}	

		.b-glazing {

			&__body {
				right: 152px;
				transform-origin: right top 0;

				&:before,
				&:after {
					left: 100%;
					top: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}

				&:after {
					border-color: rgba(255, 255, 255, 0);
					border-left-color: #ffffff;
					border-width: 8px;
					margin-top: -8px;
				}
				
				&:before {
					border-color: rgba(71, 169, 90, 0);
					border-left-color: #47a95a;
					border-width: 11px;
					margin-top: -11px;
				}				
			}
		}		
	}
}

.b-glazing {
	text-align: center;
	margin-bottom: 30px;
	position: relative;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 13px;
	}	

	&:last-child {

		@media screen and (min-width: $screen-md) {
			margin-bottom: 0;
		}
	}

	&__photo {
		width: 172px;
		height: 172px;
		@include border-radius(50%);
		overflow: hidden;
		margin: 0 auto 16px;

		img {
			display: block;
			max-width: 100%;
		}
	}

	&__title {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 10px;

		@media screen and (min-width: $screen-md) {
			height: 80px;
			padding: 0 10%;
			margin-bottom: 0;
		}

		@media screen and (min-width: $screen-md) {
			height: 68px;
		}
	}

	&__body {
		border: 2px solid #47a95a;
		padding: 10px 16px;
		@include box-sizing(border-box);
		background-color: rgba(255, 255, 255, 0.8);
		@include transition(all .4s);

		@media screen and (min-width: $screen-lg) {
			position: absolute;
			@include scale(0);
			width: 423px;
			top: 63px;			
		}	

		&:before,
		&:after {
			content: "";
			display: none;

			@media screen and (min-width: $screen-lg) {
				display: block;
			}
		}
	}

	&:hover {

		.b-glazing {
			
			&__body {
				@include scale(1);
			}	
		}	
	}
}

.c-slider-sale {
	overflow: hidden;

	.bx-viewport {
		z-index: 0;
	}

	.bx-controls-direction {
		margin: 0 auto;
		@include box-sizing(border-box);
		max-width: 996px;
		padding-left: 14px;	
		padding-right: 14px;
		position: relative;

		.bx-prev {
			background-image: url("../img/base/arr-prev-sale.png");
			left: 10px;
		}

		.bx-next {
			background-image: url("../img/base/arr-prev-sale.png");
			@include rotate(180deg);
			right: 10px;
		}

		.bx-prev,
		.bx-next {
			width: 28px;
			height: 88px;
			font-size: 0;
			position: absolute;
			bottom: 137px;
			background-repeat: no-repeat;
			background-position: 0 0;
		}
	}	
}

.slider-sale {

	.slide {
		position: relative;

		.container {
			position: relative;
			z-index: 2;
		}	

		img {
			position: absolute;
			right: 0;
			top: 0; bottom: 0;
		}
	}

	.slide-description {
		font-size: 16px;
		line-height: 20px;
		height: 362px;
		@include box-sizing(border-box);
		padding-left: 35px;
		padding-right: 35px;
		color: white;

		@media screen and (min-width: $screen-md) {
			width: 70%;
			padding-left: 74px;
			padding-right: 0;
			font-size: 23px;
			line-height: 28px;

			&_black-color {
				color: #000;
			}
		}	
	}

	.h1, .h2, .h3 {
		color: white;
		font-weight: 700;
		text-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
	}

	.h1 {
		text-transform: uppercase;
		font-size: 26px;
		line-height: 28px;
		margin-bottom: 8px;

		@media screen and (min-width: $screen-md) {
			font-size: 46px;
			line-height: 52px;
			margin-bottom: 14px;
		}
	}

	.h2 {
		text-transform: uppercase;
		font-size: 22px;
		line-height: 30px;

		@media screen and (min-width: $screen-md) {
			font-size: 37px;
			line-height: 42px;
		}
	}

	.h3 {
		font-size: 20px;
		line-height: 27px;
		margin-bottom: 23px;

		@media screen and (min-width: $screen-md) {
			font-size: 30px;
			line-height: 34px;
			margin-bottom: 43px;
		}
	}

	.slide-under-text {
		position: absolute;
		background-position: 100% 0;
		background-repeat: no-repeat;
		top: 0; bottom: 0;
		right: 0; left: 0;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.5);

		@media screen and (min-width: $screen-md) {
			right: 24%;
			background-color: transparent;
		}	

		@media screen and (min-width: $screen-lg) {
			right: 25%;
		}	

		&.bg-orange {

			@media screen and (min-width: $screen-md) {
				background-image: url("../img/base/bg-sale-under-text.png");
			}
		}

		&.bg-green {

			@media screen and (min-width: $screen-md) {
				background-image: url("../img/base/bg-sale-under-text-green.png");
			}
		}
	}
}

.valign {
	display: table;
	width: 100%;
	height: 100%;

	&-cell {
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}
}

.c-photogallery {
	background: url("../img/base/bg-photogallery.jpg") no-repeat 50% 0;
	background-size: cover;
	padding-top: 30px;
	padding-bottom: 30px;

	@media screen and (min-width: $screen-md) {
		padding-top: 65px;
		padding-bottom: 40px;
	}	
}

.gallery-slider {
	margin-bottom: 30px;
	position: relative;
	padding: 0 25px;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 62px;
		padding: 0;
	}	

	.slide {

		img {
			display: block;
			max-width: 100%;
			@include transition(all .4s);
			margin: 0 auto;
			@include scale(0.95);

			@media screen and (min-width: $screen-lg) {
				@include scale(0.85);
				@include opacity(0.5);
			}
		}

		&.slick-center {

			img {

				@media screen and (min-width: $screen-lg) {
					@include scale(1);
					@include opacity(1);
				}	
			}	
		}
	}

	.slick-arrow {
		width: 18px;
		height: 63px;
		background: url("../img/base/arr-prev-gallery.png") no-repeat;
		position: absolute;
		top: 50%;
		margin-top: -32px;
		font-size: 0;
		@include translate(0, 0);
		z-index: 10;

		&:before {
			display: none;
		}

		&.slick-prev {
			left: -4px;
		}

		&.slick-next {
			right: -4px;
			@include rotate(180deg);
		}
	}
}

.c-reviews {
	background-color: #fff; 
	padding-top: 25px;
	padding-bottom: 26px;
	overflow: hidden;

	@media screen and (min-width: $screen-md) {
		padding-top: 50px;
		padding-bottom: 46px;
		background: url("../img/base/bg-reviews.jpg") no-repeat 50% 0;
	}	

	> .container {
		position: relative;
		z-index: 2;
	}
}

.review-men {
	position: absolute;
	right: -210px;
	/*bottom: 0;*/
	top: 295px;
	z-index: -1;
	display: none;

	@media screen and (min-width: $screen-md) {
		display: block;
	}	
}

.b-audio {
	margin: 0 auto 26px;
	width: 292px;
	text-align: left;

	@media screen and (min-width: $screen-md) {
		margin-left: 0;
		margin-right: 0;
	}

	&__text {
		font-size: 14px;
		line-height: 16px;

		strong {
			display: block;
		}
	}
}

.c-audio {
	margin-bottom: 57px;
	text-align: center;

	@media screen and (min-width: $screen-md) {
		text-align: left;
	}
}

.cols-audio {
	overflow: hidden;

	&.hidden {
		display: none;
	}

	.col {

		@media screen and (min-width: $screen-md) {
			width: 40%;
			float: left;

			+ .col {
				margin-left: 35px;
			}
		}

		@media screen and (min-width: $screen-lg) {
			width: 292px;
			float: left;

			+ .col {
				margin-left: 35px;
			}
		}
	}
}

.c-media {
	background: url("../img/base/bg-media.jpg") no-repeat 50% 0;
	background-size: cover;
	padding-top: 44px;
	padding-bottom: 44px;
}

.b-video {
	max-width: 486px;

	iframe {
		width: 100%;
		height: 100%;
		display: block;
	}

	img {
		max-width: 100%;
		display: block;
	}
}

.media-description {

	&__title {
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
		margin-bottom: 12px;

		@media screen and (min-width: $screen-md) {
			font-size: 30px;
			line-height: 36px;	
		}
	}
}

.c-media-row {
	@include clearfix;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 38px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.c-media-col {
	margin-bottom: 15px;

	@media screen and (min-width: $screen-md) {
		float: left;
		width: 48%;
		margin-bottom: 0;
	}	

	&:last-child {

		@media screen and (min-width: $screen-md) {
			float: right;
		}
	}
}

.round-ad {
	font-size: 0;
	text-align: center;
	margin-bottom: 25px;

	&__col {
		width: 118px;
		text-align: center;
		display: inline-block;
		vertical-align: top;
		@include font-size($font-sm);
		padding: 14px;
		@include box-sizing(content-box);
		cursor: pointer;

		@media screen and (min-width: $screen-md) {
			margin: 0 20px;
		}

		@media screen and (min-width: $screen-lg) {
			margin: 0 50px;
		}

		&.active {
			background-color: rgba(239, 208, 187, 0.5);
		}
	}

	&__photo {
		width: 118px;
		height: 118px;
		overflow: hidden;
		@include border-radius(50%);
		margin: 0 auto 10px;

		img {
			display: block;
			max-width: 100%;
		}
	}
}

.c-questions-manager {
	background-color: #f9f9f9;
	overflow: hidden;
	position: relative;
	z-index: 2;

	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0; bottom: 0;
		z-index: -1;
		@include gradient(#ead663, #eda431, vertical);
		width: 100%;

		@media screen and (min-width: $screen-md) {
			width: 50%;
		}
	}

	&__table {
		width: 100%;

		@media screen and (min-width: $screen-md) {
			display: table;	
		}

		&-cell {
			vertical-align: middle;
			text-align: center;
			padding-top: 20px;
			padding-bottom: 20px;

			@media screen and (min-width: $screen-md) {
				display: table-cell;
				width: 50%;
				height: 414px;
				padding-top: 0;
				padding-bottom: 0;
			}

			&:first-child {
				display: none;

				@media screen and (min-width: $screen-md) {
					display: table-cell;
				}
			}
		}
	}

	.title {
		color: white;
		font-weight: 700;
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 16px;
		text-transform: uppercase;

		@media screen and (min-width: $screen-md) {
			font-size: 32px;
			line-height: 40px;
			margin-bottom: 28px;
		}
	}
}

.consultant {
	@include clearfix;
	position: relative;
	width: 100%;
	height: 100%;

	img {
		position: absolute;
		right: 0;
		top: 0; bottom: 0;
	}

	&__name {
		position: absolute;
		bottom: 25px;
		right: 0; left: 0;
		padding: 12px;
		@include box-sizing(border-box);
		font-size: 25px;
		line-height: 30px;
		text-align: left;
		z-index: 2;

		&:after {
			content: "";
			background-color: rgba(255, 255, 255, 0.66);
			position: absolute;
			right: 0;
			top: 0; bottom: 0;
			width: 9999em;
			z-index: -1
		}

		strong {
			display: block;
		}
	}
}

.c-content {
	@include gradient(#dfedf7, #fff, vertical);
	padding-bottom: 34px;
}

.c-categories {
	margin-bottom: 25px;
	padding: 38px 14px;
	@include gradient(#f3f8fc, #f7fbfd, vertical);
	font-size: 0;
}

.b-cat {
	vertical-align: top;
	font-size: 14px;
	line-height: 17px;
	color: #3a93cd;
	text-decoration: none;
	text-transform: uppercase;
	margin-bottom: 12px;
	display: block;
	@include box-sizing(border-box);

	@media screen and (min-width: $screen-md) {
		display: inline-block;
		width: 25%;
		margin-bottom: 0;
		padding: 0 20px;
		text-align: center;
	}

	@media screen and (min-width: $screen-lg) {
		padding: 0;
		text-align: left;
		vertical-align: middle;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__photo {
		display: none;
		vertical-align: middle;
		margin-bottom: 15px;

		@media screen and (min-width: $screen-md) {
			display: block;
		}

		@media screen and (min-width: $screen-lg) {
			display: table-cell;
			margin-bottom: 0;
		}
	}

	&__text {
		vertical-align: middle;

		@media screen and (min-width: $screen-md) {
			
		}

		@media screen and (min-width: $screen-lg) {
			display: table-cell;
			padding: 0 30px 0 18px;
		}
	}
}

.c-content-b {
	background-color: white;
	padding: 30px 14px;
	overflow: hidden;
}

.breadcrumbs {
	margin-bottom: 42px;

	&_sm {
		margin-bottom: 13px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			color: #3a93cd;
			position: relative;
			padding-left: 20px;

			+ li {
				margin-left: 14px;

				&:before {
					background-position: 0 -13px;
				}
			}

			&:before {
				content: "";
				width: 13px;
				height: 13px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -7px;
				background: url("../img/base/bullets-breadcrumbs.png") no-repeat;
			}

			a {
				color: #112433;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.b-article {
	margin-bottom: 30px;

	&__title {
		font-size: 22px;
		line-height: 30px;
		font-weight: 700;
		margin-bottom: 15px;
		clear: both;

		@media screen and (min-width: $screen-md) {
			font-size: 35px;
			line-height: 40px;
			margin-bottom: 22px;
		}
	}

	&__photo {
		max-width: 538px;
		margin: 0 auto 16px;

		@media screen and (min-width: $screen-md) {
			
		}	

		@media screen and (min-width: $screen-lg) {
			float: left;
			margin-bottom: 0;
		}	

		img {
			max-width: 100%;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 18px;
		position: relative;
		padding-bottom: 35px;

		@media screen and (min-width: $screen-lg) {
			margin-left: 558px;
		}

		p {
			margin-bottom: 18px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.toggle-text {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		&-inside {
			overflow: hidden;
			@include transition(all .4s);

			&.open {
				max-height: 1600px;
				display: inline;
			}
		}

		a {
			color: #112434;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__hidden {
		display: none;

		> a {
			color: #112434;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.b-benefits {
	background-color: #eff5fc;
	padding: 10px 10px;
	margin-bottom: 0;
	@include box-sizing(border-box);
	font-size: 0;

	@media screen and (min-width: $screen-md) {
		padding: 10px 30px;
	}

	.col {
		vertical-align: top;
		@include box-sizing(border-box);
		font-size: 16px;
		line-height: 18px;

		@media screen and (min-width: $screen-md) {
			width: 45%;
			display: inline-block;
		}

		@media screen and (min-width: $screen-lg) {
			vertical-align: middle;
		}	

		@media screen and (min-width: $screen-lg) {
			width: 40%;
			padding-left: 38px;
		}	

		+ .col {
			margin-top: 20px;

			@media screen and (min-width: $screen-md) {
				margin-top: 0;
				margin-left: 10%;
			}
		}
	}

	&__title {
		text-transform: uppercase;
		color: #3781cd;
		font-size: 20px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 7px;
	}
}

.unit-benefit {
	width: 100%;
	overflow: hidden;

	@media screen and (min-width: $screen-md) {
		display: table;
	}

	&__photo,
	&__text {

		@media screen and (min-width: $screen-lg) {
			display: table-cell;
			vertical-align: middle;
		}
	}

	&__photo {
		width: 80px;
		float: left;

		@media screen and (min-width: $screen-lg) {
			float: none;
			width: 128px;
		}

		img {
			display: block;
			max-width: 100%;
		}
	}

	&__text {
		margin-left: 100px;

		@media screen and (min-width: $screen-lg) {
			padding-left: 32px;
			margin-left: 0;
		}
	}
}

.hint {
	color: #939393;
	font-size: 18px;
	line-height: 20px;
	font-style: italic;
}

.c-coast {
	margin: 0 auto;
	max-width: 460px;

	@media screen and (min-width: $screen-lg) {
		margin: 0;
		max-width: 1500px;
	}	

	.col {
		margin-bottom: 25px;

		@media screen and (min-width: $screen-lg) {
			width: 44%;
			float: left;
			margin-bottom: 0;
		}	

		&:last-child {
			margin-bottom: 0;

			@media screen and (min-width: $screen-lg) {
				float: right;
				width: 52%;
			}
		}
	}
}

.c-coast-product {
	margin-bottom: 14px;
	display: table;
	width: 100%;
	color: white;
	overflow: hidden;
	background-color: #95ca44;
	text-align: center;

	&__current {
		vertical-align: middle;
		font-size: 33px;
		line-height: 33px;
		font-weight: 700;
		position: relative;
		padding: 12px;
		z-index: 2;

		@media screen and (min-width: $screen-md) {
			height: 87px;
			width: 60%;
			display: table-cell;
			padding: 0;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0; right: 0;
			top: 0; bottom: 0;
			background-color: #3781cd;
			z-index: -1;

			@media screen and (min-width: $screen-md) {
				@include rotate(25deg);
				left: -20px; right: 20px;
				top: -80px; bottom: -80px;
			}
		}
	}

	&__credit {
		vertical-align: middle;
		font-size: 21px;
		line-height: 25px;
		padding: 12px;
		text-align: center;

		@media screen and (min-width: $screen-md) {
			text-align: left;
			height: 87px;
			display: table-cell;
			width: 40%;
			padding: 0 25px;
		}
	}
}

.f-take-discount {
	overflow: hidden;

	&__title {
		text-transform: uppercase;
		font-weight: 700;
		color: #3781cd;
		font-size: 19px;
		line-height: 21px;
		margin-bottom: 12px;
		text-align: center;
	}

	&__fields {
		margin-bottom: 20px;

		@media screen and (min-width: $screen-lg) {
			float: left;
			width: 307px;
			margin-bottom: 0;
		}
	}

	&__btn {

		@media screen and (min-width: $screen-lg) {
			margin-left: 326px;
		}
	}

	button {
		background: #eea431;
		color: #fff;
		font-weight: 700;
		text-align: center;
		border: none;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 19px;
		line-height: 21px;
		padding: 14px 10px;
		@include transition(all .3s);
		width: 100%;

		@media screen and (min-width: $screen-lg) {
			padding-top: 28px;
			padding-bottom: 28px;
		}

		&:hover {
			background: #ff500b;
		}
	}
}

.cat-nav {
	font-size: 0;
	margin-bottom: 24px;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 58px;
	}	

	&__unit {
		text-transform: uppercase;
		color: #112434;
		font-size: 13px;
		line-height: 18px;
		@include box-sizing(border-box);
		margin-bottom: 13px;
		display: block;
		vertical-align: top;

		@media screen and (min-width: $screen-md) {
			text-align: center;
			display: inline-block;
			width: 33%;
			padding-right: 22px;
			padding-left: 22px;
		}

		@media screen and (min-width: $screen-lg) {
			text-align: left;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__photo {
		vertical-align: middle;
		width: 121px;
		height: 121px;
		overflow: hidden;
		@include border-radius(50%);
		display: none;
		margin: 0 auto 15px;

		@media screen and (min-width: $screen-md) {
			display: block;	
		}

		@media screen and (min-width: $screen-lg) {
			display: table-cell;	
			margin-bottom: 0;
		}
	}

	&__text {
		vertical-align: middle;

		@media screen and (min-width: $screen-md) {
			
		}

		@media screen and (min-width: $screen-lg) {
			display: table-cell;
			padding-left: 22px;
		}
	}
}

.s-text {
	font-size: 14px;
	line-height: 22px;

	p {
		margin-bottom: 20px;
	}

	ul {
		padding: 0;
		margin: 0 0 20px;
		list-style-position: inside;
	}

	ol {
		padding: 0;
		margin: 0 0 20px;
		list-style: none;
		counter-reset: heading;

		li {
			padding-left: 36px;
			position: relative;

			+ li {
				margin-top: 20px;
			}

			&:before {
				counter-increment: heading;
				content: counter(heading);
				@include border-radius(50%);
				width: 26px;
				height: 26px;
				line-height: 26px;
				text-align: center;
				background-color: #47aa5a;
				color: white;
				font-weight: 700;
				font-size: 14px;
				position: absolute;
				left: 0;
				top: -2px;
			}
		}
	}
}

.b-p-title {
	background: url("../img/base/bg-p-title.jpg") no-repeat;
	margin: -30px -14px 12px;
	height: 198px;
	background-size: cover;
}

.p-title {
	background: url("../img/base/p-title.png") no-repeat;
	max-width: 517px;
	text-align: center;
	text-transform: uppercase;
	color: white;
	font-size: 25px;
	line-height: 30px;
	font-weight: 700;
	padding: 20px 14px;
	@include box-sizing(border-box);

	@media screen and (min-width: $screen-md) {
		font-size: 40px;
		line-height: 45px;
	}
}

.c-gallery {
	padding-top: 65px;
	padding-bottom: 65px;
	background: url("../img/base/bg-gallery.jpg") no-repeat 50% 0;
	background-size: cover;
}

.slider-gallery {
	overflow: hidden;
	margin-bottom: 34px;

	.slide {
		float: left;
		width: 100%;
	}

	img {
		display: block;
		width: 100%;
	}

	a {
		display: block;
	}

	&.owl-theme {

		.owl-controls {
	    	margin: 0;
	    }
	    
	    .owl-controls {

	    	.owl-buttons {

	    		div {
					background: url("../img/base/btn-slide.png") no-repeat;
				    height: 46px;
				    margin: -23px 0 0;
				    padding: 0;
				    position: absolute;
				    text-indent: -9999px;
				    top: 50%;
				    width: 46px;
				    @include border-radius(0);
				    @include opacity(1);
	    		}

	    		.owl-prev {
    				left: 0;
	    		}

	    		.owl-next {
	    			background-position: 0 -46px;
    				right: 0;
	    		}
	    	}	
	    }
	}
}