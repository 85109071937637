@import "headings.scss";

body, html {
	height: 100%;
}

body {
	background-color: #fff;
	font-family: $font;
	font-weight: 400;
	@include font-size($font-sm);
	line-height: $line-h-sm;
	min-height: 100%;
	color: $base-color;
}

.main-container {
	/*width: 100%;*/
}

.container {
	margin: 0 auto;
	@include box-sizing(border-box);
	@include clearfix;
	max-width: 996px;
	padding-left: 14px;	
	padding-right: 14px;	
}

button {
	cursor: pointer;
}

a {
	color: $link-color;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.btn {
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	border: none;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	@include transition(all .4s);
	@include box-sizing(border-box);
	padding: 12px 20px;

	&:hover {
		text-decoration: none;
	}

	&_default {
		color: white;
		background-color: $btn-default;
		@include border-radius(3px);

		&:hover {
			background-color: $btn-default-h;
		}
	}

	&_block {
		display: block;
		width: 100%;
	}
}

.text-uppercase {
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

p {
	margin-top: 0;
	margin-bottom: 20px;
}

.thin-text {
	font-weight: 300;
}