
.main-header {
	background-color: rgba(20, 41, 63, 0.6);
	position: relative;
	z-index: 30;
	width: 100%;

	@media screen and (min-width: $screen-md) {
		text-align: left;
	}	
}

.c-header {
	padding-top: 6px;
	padding-bottom: 11px;
	text-align: center;
}

.logo {
	vertical-align: middle;
	padding-left: 78px;
	text-decoration: none;
	color: white;
	position: relative;
	background: url("../img/base/logo.png") no-repeat 0 50%;
	font-size: 33px;
	line-height: 34px;
	margin: 3px auto 15px;
	max-width: 185px;
	display: block;

	@media screen and (min-width: $screen-lg) {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		float: left;
	}	

	&:hover {
		text-decoration: none;
	}

	span {
		display: block;
		font-size: 13px;
		line-height: 14px;
	}
}

.btn-calc {
	height: 44px;
	line-height: 44px;
	background: url("../img/icons/icn-calc.png") no-repeat 0 50%;
	background-size: 55px auto;
	text-decoration: none;
	color: white;
	font-weight: 700;
	font-size: 16px;
	padding-left: 64px;
	margin: 0 auto;
	text-align: left;
	width: 110px;
	display: block;

	@media screen and (min-width: $screen-sm) {
		display: inline-block;
		vertical-align: top;
	}
		
	@media screen and (min-width: $screen-lg) {
		float: left;
		padding-left: 95px;
		margin-left: 78px;
		width: 82px;
		height: 58px;
		line-height: 58px;
		background-size: auto auto;
	}
}

.b-works {
	background-repeat: no-repeat;
	background-position: 0 50%;
	margin-top: 11px;
	color: white;
	text-align: left;
	display: inline-block;
	vertical-align: top;
	width: 49%;

	@media screen and (min-width: $screen-sm) {
		width: auto;
		margin-top: 0;
		margin-left: 30px;
	}	

	@media screen and (min-width: $screen-md) {
		padding-left: 28px;
	}
		
	@media screen and (min-width: $screen-lg) {
		margin-top: 11px;
		float: right;
		margin-left: 40px;
		width: auto;
	}	

	&__tel {
		font-size: 14px;

		@media screen and (min-width: $screen-md) {
			font-size: 18px;
			margin-bottom: 4px;
		}	

		a {
			color: white;
			text-decoration: none;
		}
	}

	&__order-call {
		font-size: 14px;

		a {
			color: white;
		}
	}
	
	@media screen and (min-width: $screen-md) {

		&.icn-tel {
			background-image: url("../img/icons/icn-tel.png");
		}

		&.icn-clock {
			background-image: url("../img/icons/icn-clock.png");
		}
	}
}

.b-mode {
	font-size: 14px;
	text-align: right;

	@media screen and (min-width: $screen-md) {
		font-size: 16px;
		text-align: left;
	}	

	&__title {

		@media screen and (min-width: $screen-md) {
			font-size: 18px;
			margin-bottom: 4px;
		}	
	}	

	span {
		font-weight: 700;
	}
}

.c-main-nav {
	background-color: rgba(20, 41, 63, 0.8);

	&.sticking {
		position: fixed;
		left: 0; right: 0;
		top: 0;
	}
}

.btn-gurger {
	width: 48px;
	height: 48px;
	line-height: 48px;
	@include transition(all .3s);
	position: relative;
	margin-left: -14px;
	cursor: pointer;
	text-align: center;

	@media screen and (min-width: $screen-lg) {
		display: none;
	}	

	span {
		display: inline-block;
		vertical-align: middle;
		width: 34px;
		position: relative;
		height: 2px;
		background-color: white;
		@include transition(all .3s);

		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 34px;
			left: 50%;
			height: 2px;
			background-color: white;
			margin-left: -17px;
			@include transition(all .3s);
		}

		&:before {
			top: -6px;
		}

		&:after {
			bottom: -6px;
		}
	}

	&.is-active {
		background-color: rgba(20, 41, 63, 1);

		span {
			background-color: transparent;

			&:before {
				-webkittransform: rotate(45deg) translate(4px, 4px);
				transform: rotate(45deg) translate(4px, 4px);
			}

			&:after {
				-webkittransform: rotate(-45deg) translate(4px, -4px);
				transform: rotate(-45deg) translate(4px, -4px);
			}
		}

		+ ul {
			display: block;
		}
	}
}

.main-nav {
	position: relative;

	> ul {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 0;
		position: absolute;
		left: -14px; right: -14px;
		top: 100%;
		background: rgba(20, 41, 63, 1);
		display: none;

		@media screen and (min-width: $screen-lg) {
			position: inherit;
			left: auto; right: auto;
			top: auto;
			background: none;
			display: block;
		}	

		> li {
			position: relative;

			@media screen and (min-width: $screen-lg) {
				width: 16.6%;
				display: inline-block;
				vertical-align: top;
			}	

			&:before {
				content: "";
				position: absolute;
				left: 1px;
				top: 50%;
				width: 0;
				height: 22px;
				margin-top: -11px;
				border-right: 1px dotted white;
				display: none;

				@media screen and (min-width: $screen-lg) {
					display: block;
				}
			}

			&:first-child {

				&:before {
					display: none;
				}
			}

			&:hover {

				+ li {

					&:before {
						display: none;
					}
				}

				> a {
					background-color: #3781cd;
				}

				> ul {

					@media screen and (min-width: $screen-lg) {
						display: block;
					}
				}
			}

			> a {
				display: block;
				text-align: center;
				text-decoration: none;
				font-size: 16px;
				line-height: 16px;
				font-weight: 700;
				text-transform: uppercase;
				padding: 20px 10px;
				@include box-sizing(border-box);
				color: white;
				@include transition(all .4s);
				position: relative;
				z-index: 2;

				&:hover,
				&.active {
					background-color: #3781cd;
					text-decoration: none;
				}
			}

			> ul {
				position: absolute;
				left: 0;
				top: 100%;
				background-color: rgba(255, 255, 255, 0.9);
				padding: 0;
				margin: 0;
				width: 330px;
				padding: 18px 22px;
				@include box-sizing(border-box);
				list-style: none;
				display: none;

				> li {

					+ li {
						margin-top: 15px;
					}

					> a {
						padding: 0 0 0 28px;
						font-weight: 700;
						text-decoration: none;
						color: #000;
						font-size: 16px;
						position: relative;
						display: inline-block;
						vertical-align: top;

						&:hover {
							text-decoration: underline;
						}

						&:before {
							content: "";
							position: absolute;
							width: 17px;
							height: 17px;
							top: 1px;
							left: 0;
							background-color: #11559c;
						}

						&:after {
							content: "";
							position: absolute;
							top: 5px;
							left: 7px;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 4px 0 4px 4px;
							border-color: transparent transparent transparent #ffffff;
						}
					}

					> ul {
						padding: 0;
						margin: 10px -22px;
						list-style: none;

						> li {

							> a {
								display: block;
								font-size: 14px;
								line-height: 16px;
								color: #000;
								padding: 10px 22px 10px 50px;
								@include transition(all .4s);
								position: relative;
								
								&:hover {
									text-decoration: none;
									background-color: #4ac7d2;
									color: white;

									&:after {
										border-color: transparent transparent transparent #ffffff;
									}
								}

								&:after {
									content: "";
									position: absolute;
									top: 13px;
									left: 29px;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 4px 0 4px 4px;
									border-color: transparent transparent transparent #37c1cd;
								}
							}
						}
					}
				}
			}
		}
	}
}