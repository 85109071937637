
h1, h2, h3, h4, h5 {
	font-weight: 700;
	margin-top: 0;
}

h1 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
}

h2 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
}

h3 {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 10px;
}

h4 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 16px;
}