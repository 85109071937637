
/* @include linx(white, blue, green, red); */
@mixin linx($link, $visit, $hover, $active) {
    a {
        color: $link;

        &:visited {
            color: $visit;
        }

        &:hover {
            color: $hover;   
        }

        &:active {
            color: $active;
        }
    }
}

/* @include box-sizing(border-box); */
@mixin box-sizing($type) {
    -webkit-box-sizing:$type;
    -moz-box-sizing:$type;
    box-sizing:$type;
}

/* @include opacity(0.5); */
@mixin opacity($opacity) {
    opacity: $opacity;
    filter: alpha(opacity=($opacity * 100));
}

/* @include font-size(14px); */
@mixin font-size($size) {
    font-size:$size;
    font-size: ($size / 16px) * 1rem;
}

/* @include box-shadow(8px, 8px); */
@mixin box-shadow( $h: 10px , $v: 10px , $b: 0px , $s: 0px , $c: #000000, $inset: false ) {
    @if not $inset {
        -webkit-box-shadow: $h $v $b $s $c;
        -moz-box-shadow: $h $v $b $s $c;
        box-shadow: $h $v $b $s $c;
    } @else if $inset == inset {
        -webkit-box-shadow: $h $v $b $s $c $inset;
        -moz-box-shadow: $h $v $b $s $c $inset;
        box-shadow: $h $v $b $s $c $inset;
    }    
}

/* @include vertical-align(); */
@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* @include flexbox(); */
@mixin flexbox {
    display:-webkit-box; // old
    display:-moz-box; // old
    display:-ms-flexbox; // ie
    display:-webkit-flex; // new
    display:flex; // new
}

/* @include flex(1, 2); */
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -ms-flex:  $values;
    -webkit-flex:  $values;
    flex:  $values;
}

/* @include border-radius(3px); */
@mixin border-radius($radius){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

/* @include flex-order(3); */
@mixin flex-order($order){
    -webkit-box-ordinal-group: $order; // old
    -moz-box-ordinal-group: $order; // old
    -ms-flex-order: $order; // ie
    -webkit-order: $order; // new
    order: $order; // new
}

/* @include flex-direction(column); */
@mixin flex-direction($direction) {
    @if $direction == column {
        -webkit-flex-direction:vertical;
        -moz-flex-direction:vertical;
        -ms-flex-direction:column;
        -webkit-flex-direction:column;
        flex-direction:column;
    } @else {
        -webkit-flex-direction:horizontal;
        -moz-flex-direction:horizontal;
        -ms-flex-direction:row;
        -webkit-flex-direction:row;
        flex-direction:row;
    }
}

/* @include gradient(#ff00ff, #ff00cc, vertical); */
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
        background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    } @else if $orientation == horizontal {
        // horizontal
        background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
        background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
        background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
        background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    } @else if $orientation == radial {
        // radial
        background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    } @else if $orientation == equal-horizontal {
        background: $start-color;
        background: -moz-linear-gradient(left,  $start-color 0%, $end-color 50%, $start-color 100%);
        background: -webkit-linear-gradient(left,  $start-color 0%, $end-color 50%, $start-color 100%);
        background: linear-gradient(to right,  $start-color 0%, $end-color 50%, $start-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
}

/* @include break-point(tablet) { margin:2em; } */
@mixin break-point($point) {
    @if $point == desktop {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    } @else if $point == tablet {
        @media only screen and (min-width: $screen-md) { 
            @content; 
        }
    } @else if $point == mobile {
        @media only screen and (min-width: $screen-sm) { 
            @content; 
        }
    }
}

/* @include clearfix; */
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/* @include rotate( 10deg ); */
@mixin rotate( $val: -2deg ) {
    -webkit-transform: rotate($val);
    -moz-transform: rotate($val);
    -ms-transform: rotate($val);
    -o-transform: rotate($val);
    transform: rotate($val);
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}



@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

@mixin scale($scale) {
    @include transform(scale($scale));
}

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin transform-origin($val) {
    -webkit-transform-origin: $val;
    -moz-transform-origin: $val;
    -ms-transform-origin: $val;
    transform-origin: $val;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}