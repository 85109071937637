.icn {
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;

	&_tel {
		background-image: url("../img/icons/icn-tel.png");
		width: 36px;
		height: 35px;

		&-wh {
			background-image: url("../img/icons/icn-tel-wh.png");
			width: 30px;
			height: 29px;
		}
	}

	&_basket {
		background-image: url("../img/icons/icn-basket.png");
		width: 36px;
		height: 35px;
	}

	&_bolts {
		background-image: url("../img/icons/icn-bolts.png");
		width: 20px;
		height: 26px;
	}

	&_washers {
		background-image: url("../img/icons/icn-washers.png");
		width: 22px;
		height: 22px;
	}

	&_washers {
		background-image: url("../img/icons/icn-washers.png");
		width: 22px;
		height: 22px;
	}

	&_screws {
		background-image: url("../img/icons/icn-screws.png");
		width: 15px;
		height: 29px;
	}

	&_pins {
		background-image: url("../img/icons/icn-pins.png");
		width: 10px;
		height: 27px;
	}

	&_anchor {
		background-image: url("../img/icons/icn-anchor.png");
		width: 12px;
		height: 29px;
	}

	&_nut {
		background-image: url("../img/icons/icn-nut.png");
		width: 24px;
		height: 21px;
	}

	&_screws {
		background-image: url("../img/icons/icn-screws.png");
		width: 15px;
		height: 28px;
	}

	&_studs {
		background-image: url("../img/icons/icn-studs.png");
		width: 11px;
		height: 28px;
	}

	&_key {
		background-image: url("../img/icons/icn-key.png");
		width: 8px;
		height: 28px;
	}
}