
.main-footer {
	background-color: #0d1823;
	padding-top: 25px;
	padding-bottom: 25px;
	color: white;
	line-height: 18px;
	text-align: center;

	@media screen and (min-width: $screen-md) {
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: left;
	}	

	.b-works {
		float: none;
		vertical-align: middle;
		margin-top: 0;
		margin-left: 0;
		display: inline-block;
		width: auto;

		@media screen and (min-width: $screen-sm-extra) {
			margin-left: 40px;
		}	

		&__tel {
			margin-bottom: 0;
		}
	}
}

.copyright {
	font-size: 13px;

	@media screen and (min-width: $screen-md) {
		float: left;
		width: 50%;
		font-size: 16px;
	}	
}

.btn-vk {
	background: url("../img/base/btn-vk.jpg") no-repeat;
	width: 139px;
	height: 33px;
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 10px;

	@media screen and (min-width: $screen-md) {
		margin-bottom: 0;
	}
}

.footer-contacts {
	margin-bottom: 25px;

	@media screen and (min-width: $screen-md) {
		float: right;
		text-align: right;
		margin-bottom: 0;
	}
}