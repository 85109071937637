
.btn {
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	background-repeat: no-repeat;
	color: $base-color;
	@include box-sizing(border-box);
	border: none;
	font-family: $font;
	@include transition(all .3s);

	&_default {
		background-color: #eea431;
		text-transform: uppercase;
		@include font-size(15px);
		line-height: 15px;
		padding: 15px;
		font-weight: 700;
		color: white;
		@include border-radius(0);

		@media screen and (min-width: $screen-md) {
			@include font-size(25px);
			line-height: 25px;
			padding: 18px;
		}	

		&:hover {
			background-color: #ff500b;
			color: #fff;
		}
	}

	&_primary {
		background-color: #3d6bb0;
		text-transform: uppercase;
		@include font-size(16px);
		line-height: 16px;
		padding: 15px;
		font-weight: 400;
		color: white;
		@include border-radius(0);

		@media screen and (min-width: $screen-md) {
			@include font-size(20px);
			line-height: 20px;
			padding: 22px 50px;
		}	

		&:hover {
			background-color: #7098d4;
			color: #fff;
		}
	}

	&_agree {
		background-color: #eea431;
		text-transform: uppercase;
		@include font-size(16px);
		line-height: 16px;
		padding: 15px 15px;
		font-weight: 400;
		color: white;
		@include border-radius(0);

		@media screen and (min-width: $screen-md) {
			@include font-size(25px);
			line-height: 25px;
			padding: 22px 35px;
		}	

		&:hover {
			background-color: #fdc15f;
			color: #fff;
		}
	}

	&_question {
		background-color: #0d1823;
		text-transform: uppercase;
		@include font-size(16px);
		line-height: 16px;
		padding: 15px 15px;
		font-weight: 400;
		color: white;
		@include border-radius(0);

		@media screen and (min-width: $screen-md) {
			@include font-size(30px);
			line-height: 30px;
			padding: 18px 35px;
		}	

		&:hover {
			background-color: #466079;
			color: #fff;
		}
	}

	&_see {
		background-color: transparent;
		text-transform: uppercase;
		@include font-size(16px);
		line-height: 16px;
		padding: 15px 15px;
		font-weight: 400;
		color: #3781cd;
		@include border-radius(0);
		@include box-shadow(0, 0, 0, 2px, #3781cd, inset);

		@media screen and (min-width: $screen-md) {
			@include font-size(25px);
			line-height: 25px;
			padding: 20px 85px;
		}	

		&:hover {
			background-color: #3781cd;
			color: #fff;
		}
	}

	&_info {
		color: white;
		text-transform: uppercase;
		padding: 18px 50px;
		@include font-size(16px);
		line-height: 16px;
	}

	&_more {
		color: #3781cd;
		@include box-shadow(0, 0, 0, 2px, #3781cd);
		text-transform: uppercase;
		padding: 15px 15px;
		@include font-size(16px);
		line-height: 16px;

		@media screen and (min-width: $screen-md) {
			padding: 18px 88px;
			@include font-size(25px);
			line-height: 25px;
		}	

		&:hover {
			color: white;
			background-color: #3781cd;
		}
	}

	&_block {
		display: block;
		width: 100%;
	}
}