@-webkit-keyframes flipZ { 
    from { 
        -webkit-transform: rotateY(0deg); 
    } 
    to { 
        -webkit-transform: rotateY(-360deg); 
    } 
} 
 
@keyframes flipZ { 
    from { 
        -moz-transform: rotateY(0deg); 
        -ms-transform: rotateY(0deg); 
        transform: rotateY(0deg); 
    } 
    to { 
        -moz-transform: rotateY(-360deg); 
        -ms-transform: rotateY(-360deg); 
        transform: rotateY(-360deg);
    } 
}

.flipZ {
    -webkit-animation-name: flipZ; 
    -webkit-animation-timing-function: linear; 
    -webkit-animation-iteration-count: infinite; 
    -webkit-animation-duration: 6s; 
 
    animation-name: flipZ;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 3s;
 
    -webkit-transform-style: preserve-3d; 
    -moz-transform-style: preserve-3d; 
    -ms-transform-style: preserve-3d; 
    transform-style: preserve-3d;
}