
$font      : 'Arial', Helvetica, sans-serif;

$font-sm    : 16px;
$line-h-sm  : 20px;

$base-color : #112434;

$btn-default  : #f36747;
$btn-default-h: #fcae4a;

$btn-update   : #f04800;

$link-color   : #3a93cd;

$screen-sm-extra : 480px;
$screen-sm       : 640px;
$screen-md       : 768px;
$screen-lg       : 996px;
$screen-lg-extra : 1100px;
$screen-lg-mextra: 1400px;